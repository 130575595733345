<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-body printableArea">
        <h3>
          <b>Daftar Drafting Dibatalkan</b>
          <!-- <span class="pull-right">#5669626</span> -->
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <Datatables table-name="drafting" @doDetail="doDetail" @doEdit="doEdit" :trigger="{status:3}" @doDelete="doDelete" :action="true" :columnFiltering="true" action-data="row_number" :url="'drafting'" :dtfields="headers">
              <template>
              <div class="btn-group" role="group" aria-label="Basic example">
                <button type="button" data-action="doDetail" class="btn btn-success"><i class="mdi mdi-magnify"></i></button>
                <!-- <button type="button" data-action="doEdit" class="btn btn-warning"><i class="mdi mdi-grease-pencil"></i></button>
                <button v-if="props.ctx.data.deleteable" type="button" data-action="doDelete" class="btn btn-danger"><i class="fa fa-times"></i></button> -->
              </div>
              </template>
            </Datatables>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this
    return {
      headers:{
        agenda_number:{
          label:'Nomor Agenda',
          data:'agenda_number',
        },
        subject:{
          label:'Perihal',
          data:'subject',
        },
        document_date:{
          label:'Tanggal',
          data:'document_date',
          render:function(data){
            return new Date(data).toDateString();
          }
        },
        document_number:{
          label:'Nomor Surat',
          data:'document_number',
        },
        sender_id:{
          label:'Pembuat',
          data:'sender.name',
        },
        final_approved:{
          label:'Status',
          data:'final_approvement.name',
          render:function(data){
            return _this.$t(data)
          }
        },
      }
    };
  },
  computed: {
  },
  mounted() {
    const state = {
      loaded: false,
      isAdd:false,
      isEdit:false,
      isDetail:false,
    };
    this.$store.commit("drafting_retracted/STATE", state);
  },
  methods: {
    doDetail(val) {
      // this.$store.dispatch("drafting_retracted/onDetail", val);
      const idsurat = val.id;
      let route = this.$router.resolve({path: '/drafting-retracted/detail'});
      let route1 = `${route.href}/${idsurat}`;
      window.open(route1,'_blank');
    },
    doEdit(val) {
      this.$store.dispatch("drafting/onEdit", val);
    },
    doDelete(val){
      this.$store.dispatch("drafting/onDelete", val);
    }
  }
};
</script>
